import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

function ListOfTracks(props) {
  const [amount, setAmount] = useState(20);
  const [expandThisIndex, setExpandThisIndex] = useState(null);

  let songsFromMutualArtistsAmount = 0;

  props.TrackList.forEach(artist => {
    songsFromMutualArtistsAmount = songsFromMutualArtistsAmount + artist.songsFromArtist.length;
  });

  const loadMore = () => {
    setAmount(amount + 20);
    console.log("loading more");
  };

  return (
    <div className={`cont black-box md:mt-4`}>
      {props.TrackList && props.showTotal && (
        <div>
          <h2 className="title mb-4 text-2xl">
            Total artists: {props.TrackList.length}
          </h2>
        </div>
      )}

      {props.TrackList && props.matches && (
        <div className="relative">
          <h2 className="title  text-2xl mb-4">{props.matches} Artist Matches:</h2>
          <p className="text-sm absolute bottom-0 ml-auto mr-auto left-0 right-0 text-center">Songs from mutual artists: {songsFromMutualArtistsAmount}</p>
        </div>
      )}

      <div
        className="max-h-52 md:max-h-96 overflow-y-auto scrollbar transition "
        id="scrollTargetArtist"
      >
        {props.TrackList ? (
          <InfiniteScroll
            dataLength={props.TrackList.slice(0, amount).length}
            next={() => loadMore()}
            hasMore={true}
            scrollableTarget="scrollTargetArtist"
          >
            {props.TrackList.slice(0, amount).map((artist, index) => (
              <div onClick={() => index === expandThisIndex ? setExpandThisIndex(null) : setExpandThisIndex(index)} className="transition-all" key={index}>
                <div  className="text-left flex items-center my-4 cursor-pointer" >
                  <img
                    src={artist.songsFromArtist[0].image}
                    alt={artist.artistName + ' album cover'}
                    className="w-20 md:w-24 mr-4"
                  />
                  <div className="flex items-center text-white w-full">
                    <p>{artist.artistName}</p>
                    {index === expandThisIndex ? <ChevronUpIcon w={8} h={8} className="ml-auto mr-4" /> : <ChevronDownIcon w={8} h={8} className="ml-auto mr-4" />}
                  </div>
                </div>
                {expandThisIndex === index && (
                  <div className="artistsSongs w-[99%] max-h-36 overflow-y-scroll text-white scrollbar mr-4 bg-black bg-opacity-10 rounded-md cursor-pointer">
                    {artist.songsFromArtist.map((song, index) => (
                      <div key={index} className="">
                        <p>{song.name}</p>
                        <div className="width-full bg-white opacity-10" style={{ height: '1px' }}></div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div className="">
            <h4>No artists here.</h4>
            <p>Refresh your page and try again.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListOfTracks;
