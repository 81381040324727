import { Spinner } from "@chakra-ui/react";
import { useStoreState, useStoreActions } from "easy-peasy";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import MakePlaylist from "../JointList/MakePlaylist";

function Wrapped({ length }) {
  const profile = useStoreState((state) => state.profile);
  const token = useStoreState((state) => state.token);

  const callAPI = useStoreActions((actions) => actions.callAPI);

  //state
  const [link, setLink] = useState("");
  const [whenError, setWhenError] = useState(false);
  const [topArtists, setTopArtists] = useState(false);
  const [topTracks, setTopTracks] = useState(false);
  const [listAmount, setListAmount] = useState(5);
  const [artistsError, setArtistsError] = useState(false);
  const [tracksError, setTracksError] = useState(false);
  const [loadingLists, setLoadingLists] = useState(true);
  // get user name
  useEffect(() => {
    if (profile && profile.customName) {
      setLink(profile.customName);
    } else if (profile && profile.id) {
      setLink(profile.id);
    }
  }, [profile]);

  useEffect(() => {
    ///get top artists
    const getTopArtistsHandler = async () => {
      const url = `https://api.spotify.com/v1/me/top/artists?time_range=${length}_term&limit=50`;
      const payload = {
        token: token,
        url: url,
      };

      if (token) {
        const data = await callAPI(payload);
        setTopArtists(data.items);
        setWhenError(false);
      } else {
        console.log("Error");
        setWhenError(true);
      }
    };
    const getTopTracksHandler = async () => {
      const url = `https://api.spotify.com/v1/me/top/tracks?time_range=${length}_term&limit=50`;
      const payload = {
        token: token,
        url: url,
      };

      if (token) {
        const data = await callAPI(payload);

        setTopTracks(data.items);
        setWhenError(false);
      } else {
        console.log("Error");
        setWhenError(true);
      }
    };

    
    const loadLists = async () => {
      try {
        await getTopArtistsHandler();
      } catch (error) {
        setArtistsError(true);
      }
      try {
        await getTopTracksHandler();
      } catch (error) {
        setTracksError(true);
      }
      setLoadingLists(false);
    }
    loadLists();
    
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex justify-center">
      <div className="black-box w-full md:w-550">
        {!profile && <Redirect to="/" />}
        {length === "short" && (
          <p className="text-2xl mb-4 font-bold ">Your past 4 weeks wrapped!</p>
        )}
        {length === "medium" && (
          <p className="text-2xl mb-4 font-bold ">
            Your past 6 months wrapped!
          </p>
        )}
        {length === "long" && (
          <p className="text-2xl mb-4 font-bold ">All time wrapped!</p>
        )}
        {topArtists && topArtists[0] && topArtists[0].images ? (
          <img
            className="w-3/4 mx-auto md:w-52 pb-4"
            src={topArtists[0].images[0].url}
            alt={`${topArtists[0].name}`}
          />
        ) : (
          //loading block
          <div className="w-3/4 h-3/4 mx-auto md:w-52 md:h-52 pb-4 transition animate-bgCycle bg-black bg-opacity-30 rounded-2xl shadow-2xl p-4 md:p-8 mb-4">
          </div>
        )}
        <p>Compare music with me at:</p>
        <a className="font-bold text-white " target="_blank" href="/">
          JointPlaylist.com/s/{link}
        </a>
        {whenError && <p> Error</p>}

        <div className="flex justify-center space-x-4 md:space-x-20 mt-4 md:text-base text-xs">
          <div className="w-1/2 md:w-auto">
            <p className="text-sm md:text-lg">Top Artists</p>
            {loadingLists ? (
              <Spinner size="lg" color="white" className="my-2"></Spinner>
            ) : (
              <>
                {artistsError && (
                  <p>Error loading top artists {':('}</p>
                )}
                {topArtists && !artistsError && !loadingLists &&
                  topArtists.slice(0, listAmount).map((artist, index) => (
                    <div key={index}>
                      <p className="text-left">
                        {index + 1}. {artist.name}
                      </p>
                    </div>
                  ))}
              </>
            )}
          </div>
          <div className="w-1/2 md:w-auto">
            <p className="text-sm md:text-lg">Top Tracks</p>
            {loadingLists ? (
              <Spinner size="lg" color="white" className="my-2"></Spinner>
            ) : (
              <>
                {tracksError && (
                  <p>Error loading top tracks {':('}</p>
                )}
                {topTracks && !tracksError &&
                  topTracks.slice(0, listAmount).map((track, index) => (
                    <div key={index}>
                      <p className="text-left">
                        {" "}
                        {index + 1}. {track.name.slice(0, 25)}
                        {track.name.length > 25 ? "..." : ""}
                      </p>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        {listAmount < 6 && <div className="cursor-pointer underline text-white m-4" onClick={() => setListAmount(999)}>Show More</div>}
        {listAmount > 6 && topTracks && (
          <div className="mt-4">
            <MakePlaylist overwriteDefaultList={topTracks} alwaysWrappedLength={length} />
          </div>
        )}
        {listAmount > 6 && <div className="cursor-pointer underline text-white m-4" onClick={() => setListAmount(5)}>Show Less</div>}
      </div>
    </div>
  );
}

export default Wrapped;
