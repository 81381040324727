import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

import JointList from "../JointList/JointList";

function Compare() {
  //local state
  const [compareLoading, setCompareLoading] = useState(null);
  //easy state
  let myTrackListToCompare = useStoreState(
    (state) => state.myTrackListToCompare
  );
  let persistFriendsTrackList = useStoreState(
    (state) => state.persistFriendsTrackList
  );
  let jointList = useStoreState((state) => state.jointList);

  //easy actions
  const setJointList = useStoreActions((actions) => actions.setJointList);

  //handlers
  const compareTracksHandler = () => {
    setCompareLoading(true);
    const theirs = persistFriendsTrackList.theList;
    const yours = myTrackListToCompare.theList;
    let jointListSongs = [];
    let jointListArtists = [];
    if (yours && theirs) {
      //always use the larger list as a base to correctly get all artist matches
      if (yours.length < theirs.length) {
        theirs.forEach((theirSong) => {
          if (yours.some((me) => me.uri === theirSong.uri)) {
            jointListSongs.push(theirSong);
          }

          if (yours.some((me) => me.artist === theirSong.artist)) {
            const index = jointListArtists.findIndex(
              (item) => item.artistName === theirSong.artist
            );
            //if doesn't find an index
            if (index === -1) {
              const artistObj = {
                artistName: theirSong.artist,
                songsFromArtist: [theirSong],
              };
              jointListArtists.push(artistObj);
            } else {
              //if finds index then push the song into that
              jointListArtists[index].songsFromArtist.push(theirSong);
            }
          }
        });

        //loop to get all songs from matching artists
        yours.forEach((yourSong) => {
          if (
            jointListArtists.some(
              (joint) => joint.artistName === yourSong.artist
            )
          ) {
            const index = jointListArtists.findIndex(
              (item) => item.artistName === yourSong.artist
            );
            //check if  list contains that song already
            if (
              !jointListArtists[index].songsFromArtist.some(
                (check) => check.name === yourSong.name
              )
            ) {
              jointListArtists[index].songsFromArtist.push(yourSong);
            }
          }
        });
      } else {
        yours.forEach((i) => {
          if (theirs.some((me) => me.uri === i.uri)) {
            jointListSongs.push(i);
          }
          if (theirs.some((me) => me.artist === i.artist)) {
            const index = jointListArtists.findIndex(
              (item) => item.artistName === i.artist
            );
            //if doesn't find an index
            if (index === -1) {
              const artistObj = {
                artistName: i.artist,
                songsFromArtist: [i],
              };
              jointListArtists.push(artistObj);
            } else {
              //if finds index then push the song into that
              jointListArtists[index].songsFromArtist.push(i);
            }
          }
        });

        //loop to get all songs from matching artists
        theirs.forEach((theirSong) => {
          if (
            jointListArtists.some(
              (joint) => joint.artistName === theirSong.artist
            )
          ) {
            const index = jointListArtists.findIndex(
              (item) => item.artistName === theirSong.artist
            );
            //check if  list contains that song already
            if (
              !jointListArtists[index].songsFromArtist.some(
                (check) => check.name === theirSong.name
              )
            ) {
              jointListArtists[index].songsFromArtist.push(theirSong);
            }
          }
        });
      }

      const final = {
        theList: jointListSongs,
        theArtistsList: jointListArtists,
        userCreatorName: myTrackListToCompare.name,
        userCreatorID: myTrackListToCompare.id,
        userFriendName: persistFriendsTrackList.name,
        userFriendID: persistFriendsTrackList.id,
      };
      setJointList(final);
      setCompareLoading(false);
    }
  };

  useEffect(() => {
    myTrackListToCompare &&
      persistFriendsTrackList &&
      !compareLoading &&
      !jointList &&
      compareTracksHandler();
    return () => {};
    // eslint-disable-next-line
  }, [myTrackListToCompare]);

  return (
    <div>
      {compareLoading && <p>Comparing...</p>}
      {jointList && <JointList />}
    </div>
  );
}

export default Compare;
